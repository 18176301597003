@import '../../styles/partials/variables';

.landing_wrapper {
  margin-top: calculateRem(20px);
  margin-bottom: calculateRem(44px);
  
  @include respond-to('mobile2') {
    margin-top: calculateRem(32px);
    margin-bottom: calculateRem(44px);
  }

  @include respond-to('tablet') {
    margin-top: calculateRem(40px);
    margin-bottom: calculateRem(66px);
  }

  @include respond-to('desktop') {
    margin-top: calculateRem(60px);
    margin-bottom: calculateRem(90px);
  }
}

.card_holder {
  @include respond-to('tablet') {
    @include grid-col(5, $grid-columns: 9, $gutter: $gap_tablet);
  }
  @include respond-to('desktop') {
    @include grid-col(5, $grid-columns: 9, $gutter: $gap_desktop);
  }
}

.home_landing {
  margin-bottom: calculateRem(24px);
  
  > div {
    &:nth-child(1) {
      background: $color__background;
      box-shadow: none;

      > div {
        &:first-child {
          box-shadow: $site_box_shadow;

          @include respond-to('tablet') {
            margin-bottom: 0;
          }
        }

        //feature home landing image
        &:last-child {
          padding: calculateRem(33px) 0 calculateRem(36px);
          
          h3 {
            @include convertToRem(25px);
            line-height: 31px;
            margin-bottom: calculateRem(14px);

            @include respond-to('mobile2') {
              @include convertToRem(35px);
              line-height: 40px;
            }
          }
        }
      }

    }

    &:nth-child(2) {
      border-top: 1px solid $color__rule-lines;
      padding-top: calculateRem(24px);

    }

    &:nth-child(3) {
      padding-top: calculateRem(24px);
      padding-bottom: calculateRem(24px);
      border-bottom: 1px solid $color__rule-lines;

      @include respond-to('tablet') {
        border-bottom: 0;
      }
    }
  }
}

.filter_holder {
  > div { //container
    @include respond-to('tablet') {
      flex-direction: column;
    }

    > div {  //filtered_content
      margin-bottom: calculateRem(20px);

      @include respond-to('tablet') {
        margin-bottom: 0;
      }

      > div { //article__card
        padding-top: calculateRem(24px);
        padding-bottom: calculateRem(24px);
        border-bottom: 1px solid $color__rule-lines;

        &:last-child {
          @include respond-to('tablet') { 
            border-bottom: 0;
          }
        }

        &:nth-last-child(2) {
          @include respond-to('tablet') { 
            border-bottom: 0;
          }
        }
        
        &:first-child {
          border-top: 1px solid $color__rule-lines;

          @include respond-to('tablet') {
            border-top: 0;
          }
        }
      }
    }
  }

  @include respond-to('tablet') {
    @include grid-col(4, $grid-columns: 9, $gutter: $gap_tablet);
  }

  @include respond-to('desktop') {
    @include grid-col(4, $grid-columns: 9, $gutter: $gap_desktop);
  }
}

@import '../../../styles/partials/mixins';
@import '../../../styles/partials/variables';

.socialBar {
  margin-top: calculateRem(48px);
  width: 100%;

  @include respond-to('mobile2') {
    margin-top: 0;
  }

  h3 {
    font-family: $font__secondary;
    font-weight: $bold-weight;
    @include convertToRem(17px);
    line-height: 20px;
    margin-bottom: calculateRem(12px);
    color: $color__wheat;

    @include respond-to('mobile2') {
      @include convertToRem(19px);
      line-height: 23px;
      letter-spacing: 0.04em;
      margin-bottom: calculateRem(11px);
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include respond-to('mobile2') {
      justify-content: left;
    }
    @include respond-to('tablet') {
      justify-content: left;
    }
    @include respond-to('desktop') {
      justify-content: left;
    }

    li {
      padding-right: calculateRem(22px);

      @include respond-to('mobile2') {
        padding-right: calculateRem(16px);
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        color: white;

        &:hover {
          color: $color__wheat;
        }

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

@import '../../styles/partials/variables';

.filters {
  display: flex;
  flex-flow: row wrap;
  // padding-bottom: calculateRem(24px);
  margin-top: calculateRem(-12px);
  margin: 0 16px;
}

.filter {
  padding: calculateRem(12px) calculateRem(13px);
  border-radius: 500px;
  background: $color_light-gray;
  font-family: $font__main;
  font-weight: $bold-weight;
  line-height: 1.27;
  color: $color__gray-black-dark;
  text-transform: uppercase;
  margin: calculateRem(12px) calculateRem(6px) 0 0;
  cursor: pointer;
  @include convertToRem(11px);
  @include letter-spacing(69);
  &:hover {
    background: $color__green;
    color: #fff;
  }
  &:last-child {
    margin-right: 0;
  }
  width: fit-content!important;
  margin: 0 8px;
}

.filter_active {
  background: $color__green;
  color: #fff;
}

.filtered_content {
  @include respond-to('tablet') {
    @include grid($flex-direction: row, $flex-wrap: wrap);
    width: 100%;
  }

  > div {
    &:nth-child(even) {
      margin-right: 0;
    }

    @include respond-to('tablet') {
      width: 45.8%;
      margin-right: 30px;
    }

    @include respond-to('desktop') {
      width: 45.8%;
      margin-right: 40px;
    }
  }
}


.filtered_list {
  // max-width: 50%;
  // max-width: 500px;
  max-width: 500px;
  display: flex;
//   // width: min-content;
}

@media (max-width: 1225px) {
  .filtered_list {
    max-width: 400px;
  }
}

@media (max-width: 912px) {
  .filtered_list {
    max-width: 100%;
  }
}

.seemore {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;

  a {
    font-family: $font__main;
    font-weight: $semibold-weight;
    line-height: 1.5;
    color: $color__gray-dark;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 2px solid $color__green;
    @include convertToRem(12px);
    @include letter-spacing(69);

    &:hover {
      color: $color__green;
    }
  }
}

@import '../../styles/partials/variables';

.conversation__outer_wrapper {
  margin: 44px auto;

  @include respond-to('tablet') {
    margin: 66px auto;
  }

  @include respond-to('desktop') {
    margin: 90px auto;
  }
}

.conversations_title {
  display: flex;
  align-items: center;
  margin-bottom: calculateRem(24px);

  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);
  }

  @include respond-to('desktop') {
    @include grid-col(9, $grid-columns: 9);
  }

  h2 {
    font-family: $font__poppins;
    font-weight: $bold-weight;
    color: $color__green;
    @include convertToRem(20px);
    line-height: 30px;
    margin-right: calculateRem(12px);
  }

  a {
    order: 3;
    font-family: $font__main;
    font-weight: $semibold-weight;
    line-height: 15px;
    color: $color__green;
    text-transform: uppercase;
    margin-left: calculateRem(12px);
    text-decoration: none;
    flex-shrink: 0;
    @include convertToRem(12px);
    @include letter-spacing(69);

    &:hover {
      color: $color__gray-black-dark;
    }
  }

  &::after {
    content: '';
    width: 100%;
    flex: 1;
    height: 3px;
    display: block;
    background: $color__green;
  }
}

.converations__inner {
  background: $color__light-wheat;
  box-shadow: $site_box_shadow;
  margin-right: $site_box_shadow_size;
  overflow: hidden;
  width: 100%;
  padding: calculateRem(24px) calculateRem(16px) calculateRem(28px);

  @include respond-to('mobile2') {
    padding: calculateRem(28px);
  }

  @include respond-to('tablet') {
    flex-wrap: nowrap;
  }
}

.conversations__posts {
  overflow: hidden;
  margin-bottom: calculateRem(24px);
  @include grid($flex-direction: row, $flex-wrap: wrap);

  @include respond-to('mobile2') {
    @include grid($flex-direction: row, $flex-wrap: wrap);
    margin-bottom: calculateRem(28px);
  }
}

.conversation__post {
  display: flex;
  flex-direction: column;
  padding-bottom: calculateRem(24px);
  border-bottom: 1px solid $color__rule-lines;
  @include grid-col(2, $grid-columns: 4);
  padding-right: calculateRem(28px);
  padding-left: calculateRem(28px);

  @include respond-to('mobile') {
    @include grid-col(1, $grid-columns: 1);
    padding-bottom: calculateRem(28px);
    border-right: 1px solid $color__rule-lines;
  }

  @media (min-width: calculateRem(1024px)) {
    @include grid-col(1, $grid-columns: 3);
    border-right: 1px solid $color__rule-lines;
    padding-left: calculateRem(28px);
  }
}

.conversation__heading {
  display: flex;
  align-items: center;
  margin-bottom: calculateRem(16px);
}

.conversation__author_avatar {
  width: 58px;
  height: 58px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: calculateRem(14px);
}

.conversation__author_name {
  @include convertToRem(17px);
  line-height: 20px;
  color: $color__green;
  font-family: $font__secondary;
  font-weight: $bold-weight;
}

.conversation__author_twitter {
  @include convertToRem(14px);
  line-height: 22px;
  color: $color__gray-black-dark;
  font-family: $font__labels;

  a {
    color: $color__gray-black-dark;
    font-family: $font__labels;

    &:hover {
      transition: opacity 400ms ease;
      opacity: 0.7;
    }
  }
}

.conversation__content {
  color: $color__gray-black-dark;
  @include convertToRem(15px);
  line-height: 22px;
  margin-bottom: calculateRem(12px);
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 108px; /* fallback */
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include respond-to('mobile2') {
    margin-bottom: calculateRem(30px);
  }
}

.converation__button_container {
  margin-left: auto;
}

.conversation__button {
  color: transparent;
  outline: none;
  background: transparent;
  border: 0px;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  padding: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 400ms ease;
  }
}

.conversations__carousel {
  display: flex;
  width: 100%;
}

.conversation__date {
  font-family: $font__main;
  font-weight: $semibold-weight;
  color: $color__gray-dark;
  @include convertToRem(12px);
  line-height: 15px;
  @include letter-spacing(69);
}

.author__list_wrapper {
  width: 100%;
  margin-bottom: calculateRem(28px);

  @include respond-to('mobile2') {
    margin-bottom: calculateRem(28px);
  }

  @include respond-to('tablet') {
    padding-left: calculateRem(28px);
    margin-left: calculateRem(28px);
    border-left: 1px solid $color__rule-lines;
  }
}

.author__header {
  text-transform: uppercase;
  text-align: center;
  font-family: $font__main;
  font-weight: $semibold-weight;
  color: $color__gray-black;
  @include letter-spacing(69);
  @include convertToRem(16px);
  padding-bottom: calculateRem(24px);
  border-bottom: 1px solid $color__rule-lines;

  @include respond-to('mobile2') {
    padding-bottom: calculateRem(28px);
  }
}

.author__list__author_bottom {
  display: flex;
  border-bottom: 1px solid $color__rule-lines;
  padding: calculateRem(16px) 0;
}

.author__list__author_inner {
  display: flex;
  align-items: center;
  width: 100%;

  @include respond-to('tablet') {
    padding-right: calculateRem(12px);
  }
}

.author__list__author_image {
  width: 37px;
  height: 37px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: calculateRem(12px);
}

.author__list__author_name {
  @include convertToRem(15px);
  line-height: 18px;
  color: $color__green;
  font-family: $font__secondary;
  font-weight: $bold-weight;
}

.author__list__author_twitter {
  color: $color__gray-black;
  font-family: $font__labels;
  @include convertToRem(12px);
  line-height: 22px;
}

.author__list__follow {
  @include reset-button;
  margin-left: auto;
  font-family: $font__secondary;
  font-size: calculateRem(13px);
  line-height: calculateRem(15px);
  font-weight: $bold-weight;
  border-radius: 18px;
  border: 2px solid $color__green;
  background-color: $color__light-wheat;
  color: $color__green;
  width: calculateRem(78px);
  height: 32px;
  text-decoration: none;

  &:hover {
    background-color: $color__green;
    color: $color__light-wheat;
  }
}

@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.paragraph {
  @include convertToRem(18px);
  line-height: 30px;
  color: $color__gray-black;
  margin-top: calculateRem(30px);
  margin-bottom: calculateRem(30px);

  em {
    font-style: italic;
  }

  strong {
    font-weight: $black-weight;
  }

  a {
    color: $color__gray-black;

    &:hover {
      text-decoration: none;
    }
  }
}
.anchor-text {
  @include convertToRem(13px);
  line-height: calculateRem(15px);
  color: #fff;
  font-family: $font__secondary;
  font-weight: $bold-weight;
  background-color: $color__green;
  padding: calculateRem(11px) calculateRem(30px);
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  a  {
    color: #fff;
    text-decoration: none;
  }
  }
@import '../../styles/partials/variables';

.heading1 {
  line-height: 40px;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(17px);
  @include convertToRem(35px);
}

.heading2 {
  line-height: 31px;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(14px);
  @include convertToRem(25px);

  + p {
    margin-top: 0;
  }
}

.heading3 {
  line-height: 21px;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(12px);
  @include convertToRem(15px);

  + p {
    margin-top: 0;
  }
}

.heading4 {
  line-height: 1;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(10px);
  @include convertToRem(12px);

  + p {
    margin-top: 0;
  }
}

.heading5 {
  line-height: 1;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(10px);
  @include convertToRem(11px);

  + p {
    margin-top: 0;
  }
}

.heading6 {
  line-height: 1;
  color: $color__green;
  font-family: $font__poppins;
  font-weight: $xbold-weight;
  margin-bottom: calculateRem(10px);
  @include convertToRem(10px);

  + p {
    margin-top: 0;
  }
}

@import '../../styles/partials/variables';

// Button Transition with Borders from https://codepen.io/chriscoyier/pen/bojzwJ
$border-top-bottom-width: 21px;
$border-left-width: 30px;

.audio__btn {
  @include reset-button;
  width: 0;
  height: 42px;

  border-color: transparent transparent transparent $color__green;
  transition: 100ms all ease;
  cursor: pointer;

  &__play {
    border-style: solid;
    border-width: $border-top-bottom-width 0 $border-top-bottom-width $border-left-width;
  }

  &__pause {
    border-style: double;
    border-width: 0px 0 0px $border-left-width;
  }

  &:hover {
    border-color: transparent transparent transparent $color__gray-black-dark;
  }
}

@import '../../styles/partials/variables';

.button__primary {
  background-color: $color__green;
  border-radius: 500px;
  color: #fff;
  line-height: 1.14;
  font-family: 'Paralucent', sans-serif;
  font-weight: $bold-weight;
  padding: calculateRem(13px) calculateRem(23px);
  border: 2px solid $color__green;
  display: block;
  text-decoration: none;
  cursor: pointer;
  @include convertToRem(17px);

  &:hover {
    background-color: $color__background;
    color: $color__green;
  }
}
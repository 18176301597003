@import '../../styles/partials/variables';

.partners {
  &__outer_wrapper {
    position: relative;
    display: flex;
    background-color: $color__light-yellow;
    width: calc(100% + 2.5rem);
    justify-content: center;
    align-items: center;
    margin: 44px -1.25rem;

    @include respond-to('tablet') {
      margin: 66px -1.875rem;
      width: calc(100% + 3.75rem);
    }

    @include respond-to('desktop') {
      margin: 90px -2.5rem;
      width: calc(100% + 5rem);
    }
  }

  &__inner_wrapper {
    width: calc(100% - 2.5rem);
    margin: 44px auto;

    @include respond-to('tablet') {
      flex-wrap: wrap;
      margin: 66px auto;
      width: calc(100% - 3.75rem);
    }

    @include respond-to('desktop') {
      margin: 90px auto;
      width: calc(100% - 5rem);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    @include grid-col(2, $grid-columns: 2);

    @include respond-to('mobile') {
      @include grid-col(4, $grid-columns: 4);
    }

    @include respond-to('tablet') {
      @include grid-col(9, $grid-columns: 9);
    }

    @include respond-to('desktop') {
      @include grid-col(9, $grid-columns: 9);
    }

    h2 {
      font-family: $font__poppins;
      font-weight: $xbold-weight;
      color: $color__green;
      line-height: 30px;
      margin-right: calculateRem(12px);
      flex-shrink: 0;
      @include convertToRem(20px);
    }

    a {
      order: 3;
      font-family: $font__main;
      font-weight: $semibold-weight;
      line-height: 15px;
      color: $color__green;
      text-transform: uppercase;
      margin-left: calculateRem(12px);
      text-decoration: none;
      flex-shrink: 0;
      @include convertToRem(12px);
      @include letter-spacing(69);

      &:hover {
        color: $color__gray-black-dark;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      display: block;
      background: $color__green;
    }
  }

  &__content {
    display: grid;
    grid-row-gap: calculateRem(64px);
    width: 100%;
    margin-top: calculateRem(67px);

    @include respond-to('tablet') {
      $column: calc(50% - #{$padding_tablet / 2});
      grid-column-gap: $padding_tablet;
      grid-template-columns: $column $column;
    }

    @include respond-to('desktop') {
      $column: calc(33.33% - #{$padding_desktop * 2 / 3});
      grid-column-gap: $padding_desktop;
      grid-template-columns: $column $column $column;
    }
  }
}

.partner {
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
  }

  &__image {
    flex: 0 0 calculateRem(93px);
    width: calculateRem(93px);
    height: calculateRem(93px);
    border: 1px solid $color__rule-lines;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
      object-fit: contain;
    }
  }

  &__text {
    flex: 1 1 auto;
    padding-left: calculateRem(24px);
  }

  &__link {
    display: block;
    font-family: $font__main;
    font-weight: $semibold-weight;
    line-height: 1.25;
    color: $color__gray-dark;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 4px;
    margin-bottom: 5px;
    @include convertToRem(12px);
    @include letter-spacing(69);

    &:hover {
      color: $color__green;
    }
  }

  &__headline {
    font-family: $font__poppins;
    font-weight: $bold-weight;
    color: $color__green;
    line-height: 21px;
    margin-bottom: calculateRem(6px);
    @include convertToRem(15px);

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $color__gray-black-dark;
      }
    }
  }

  &__excerpt {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    color: $color__gray-dark;
    margin-bottom: calculateRem(16px);
    @include convertToRem(14px);
  }
}
@import '../../styles//partials/mixins';
@import '../../styles/partials/variables';

.footer {
  text-align: center;
  background: $color__logo-green;
  padding: calculateRem(20px) calculateRem(16px);
  border-bottom: $color__wheat calculateRem(11px) solid;
  color: #fff;

  &__inner {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__left {
    @include respond-to('tablet') {
      padding-left: calculateRem(30px);
    }

    @include respond-to('desktop') {
      padding-left: 0;
    }
  }

  &__right {
    margin-top: calculateRem(72px);
    margin-bottom: calculateRem(92px);

    @include respond-to('mobile2') {
      width: 100%;
    }

    @include respond-to('tablet') {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-right: calculateRem(30px);
      padding-left: calculateRem(77px);
    }

    @include respond-to('desktop') {
      padding-right: calculateRem(40px);
      padding-left: calculateRem(104px);
    }
  }

  &__right__inner {
    @include respond-to('mobile2') {
      display: flex;
      width: 100%;
    }
    @include respond-to('tablet') {
      justify-content: flex-end;
    }
  }

  &__right__navlinks {
    @include respond-to('mobile2') {
      flex: 1;
    }
  }

  &__right__addressSocial {
    @include respond-to('mobile2') {
      flex: 1;
    }
    @include respond-to('tablet') {
      padding-left: calculateRem(53px);
    }
    @include respond-to('desktop') {
      padding-left: calculateRem(109px);
      width: calculateRem(440px);
    }
  }

  &__logo {
    p {
      font-family: 'Poppins', sans-serif;
      text-align: center;

      &:first-of-type {
        @include convertToRem(35px);
        line-height: 1;
        font-weight: $bold-weight;
        color: $color__background;
      }
      &:nth-of-type(2) {
        @include convertToRem(30px);
        line-height: 1;
        color: $color__background;
      }
    }
  }

  &__address {
    @include convertToRem(15px);
    line-height: 23px;
    margin-bottom: calculateRem(92px);

    h3 {
      display: none;
    }
  }

  a {
    color: $color__wheat;
    text-decoration: none;

    &:hover {
      color: $color__light-wheat;
    }
  }

  &__full {
    margin-bottom: calculateRem(31px);
  }

  &__section {
    width: 100%;

    &__legal {
      margin-bottom: calculateRem(16px);

      ul {
        display: flex;
        align-items: center;
        justify-content: space-around;

        a,
        li {
          font-family: $font__main;
          font-weight: normal;
          letter-spacing: 0.07em;
          line-height: 17px;
          color: $color__wheat;
          @include convertToRem(13px);
        }
      }
    }
  }

  &__hdmGroupLogo {
    &__mobile {
      width: 115px;
      height: 25px;
      object-fit: contain;
    }
    &__mobile2 {
      display: none;
    }
  }

  .partnersHeader {
    width: 100%;
    font-size: 24px;
    text-decoration: underline;
    text-align: center;
  }

  .partnersContainer {
    display: flex;
    gap: 16px 32px;
    justify-content: center;
    align-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 24px auto;
  }

  .partner {
    width: 150px;
    height: auto;

    img, a {
      width: inherit;
    }
  }

  @include respond-to('mobile2') {
    padding: calculateRem(32px) 0 0;
    border-bottom: 0;
    text-align: left;

    &__inner {
      max-width: 345px;
    }

    &__address {
      margin-bottom: calculateRem(31px);

      h3 {
        display: block;
        font-family: $font__secondary;
        @include convertToRem(19px);
        line-height: 23px;
        font-weight: $bold-weight;
        letter-spacing: 0.04em;
        color: $color__wheat;
        margin-bottom: calculateRem(11px);
      }
    }

    &__full {
      margin-bottom: 0;
      padding-top: calculateRem(12px);
      padding-bottom: calculateRem(12px);
      padding-left: calculateRem(35px);
      padding-right: calculateRem(35px);
      background-color: $color__wheat;

      &__inner {
        max-width: $max_width;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }
    }

    &__section {
      width: 100%;

      &__legal {
        margin-bottom: 0;
        flex-grow: 1;

        ul {
          justify-content: flex-end;

          > li {
            padding-right: calculateRem(21px);

            &:last-child {
              padding-right: 0;
            }
          }

          a,
          li {
            font-family: $font__main;
            font-weight: normal;
            letter-spacing: 0.07em;
            line-height: 17px;
            color: $color__green;
            @include convertToRem(13px);
          }

          a {
            &:hover {
              color: $color__gray-black-dark;
            }
          }
        }
      }
    }

    &__hdmGroupLogo {
      &__mobile {
        display: none;
      }
      &__mobile2 {
        display: block;
        width: 115px;
        height: 25px;
        object-fit: contain;
      }
    }
  }

  @include respond-to('tablet') {
    padding: calculateRem(50px) 0 0;

    &__inner {
      max-width: $max_width;
      flex-direction: row;
      align-items: flex-start;
      padding-bottom: calculateRem(65px);
    }

    &__full {
      padding-left: calculateRem(30px);
      padding-right: calculateRem(30px);
    }

    &__logo {
      p {
        text-align: left;
      }
    }
  }

  @include respond-to('desktop') {
    padding: calculateRem(57px) 0 0;

    &__inner {
      padding-bottom: calculateRem(94px);
    }

    &__full {
      padding-left: calculateRem(40px);
      padding-right: calculateRem(40px);
    }
  }
}

@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.figure {
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  figcaption {
    margin-top: 27px;
    font-family: $font__main;
    font-size: calculateRem(12px);
    line-height: 15px;
    font-weight: 600;
    color: $color__gray-dark;
    letter-spacing: 0.07em;
  }
}

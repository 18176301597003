@import '../../styles/partials/variables';

.article__cards {
  width: 100%;
  grid-row-gap: calculateRem(24px);

  @include respond-to('tablet') {
    flex-wrap: wrap;
  }
}

.article__cards_title {
  display: flex;
  align-items: center;
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);
  }

  @include respond-to('desktop') {
    @include grid-col(9, $grid-columns: 9);
  }

  h2 {
    font-family: $font__poppins;
    font-weight: $xbold-weight;
    color: $color__green;
    line-height: 30px;
    margin-right: calculateRem(12px);
    flex-shrink: 1;
    @include convertToRem(20px);

    @include respond-to('tablet') {
      flex-shrink: 0;
    }
  }

  a {
    order: 3;
    font-family: $font__main;
    font-weight: $semibold-weight;
    line-height: 15px;
    color: $color__green;
    text-transform: uppercase;
    margin-left: calculateRem(12px);
    text-decoration: none;
    flex-shrink: 0;
    @include convertToRem(12px);
    @include letter-spacing(69);

    &:hover {
      color: $color__gray-black-dark;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background: $color__green;
  }
}

.card__feature_small {
  width: 100%;

  @include respond-to('tablet') {
    @include grid-col(6, $grid-columns: 9, $gutter: $gap_tablet);
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    @include grid-col(5, $grid-columns: 9, $gutter: $gap_desktop);
    margin-bottom: 0;
  }
}

.card__list_small {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile2') {
    @include grid-col(4, $grid-columns: 4);
    @include grid($flex-wrap: wrap);
  }

  @include respond-to('tablet') {
    @include grid-col(3, $grid-columns: 9, $gutter: $gap_tablet);
    margin-bottom: 0;
  }

  @media (min-width: 913px) and (max-width: 1237px) {
    display: block;
  }

  @include respond-to('desktop') {
    @include grid-col(4, $grid-columns: 9, $gutter: $gap_desktop);
    margin-bottom: 0;
  }
}

.card__feature_small_condensed {
  margin-top: $gap_tablet;
  @include grid();

  > div {
    @include grid-col(3, $grid-columns: 6, $gutter: $gap_tablet);
    margin-bottom: 0;

    @include respond-to('tablet') {
      padding: 0;
      border: 0;

    }
  }
}

.card__feature_medium {
  @include grid-col(2, $grid-columns: 2);
  flex-basis: calc(100% - 9px);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
    flex-basis: calc(100% - 9px);
  }

  @include respond-to('tablet') {
    @include grid-col(6, $grid-columns: 9, $gutter: $gap_tablet);
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    @include grid-col(6, $grid-columns: 9, $gutter: $gap_desktop);
    margin-bottom: 0;
  }
}

.card__list_medium {
  width: 100%;

  @include respond-to('mobile2') {
    @include grid($flex-wrap: wrap);
  }

  @include respond-to('tablet') {
    @include grid-col(3, $grid-columns: 9, $gutter: $gap_tablet);
    margin-bottom: 0;
    display: block;
  }

  @include respond-to('desktop') {
    @include grid-col(3, $grid-columns: 9, $gutter: $gap_desktop);
    margin-bottom: 0;
  }

  > div {
    padding-top: calculateRem(24px);
    padding-bottom: calculateRem(24px);
    border-bottom: 1px solid $color__rule-lines;

    @media (min-width: 280px) and (max-width: 614px) {
      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    @include respond-to('mobile2') {
      @include grid-col(1.92, $grid-columns: 4);
    }

    @media (min-width: 615px) and (max-width: 912px) {
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      &:nth-last-child(2) {
        border-bottom: 0;
        padding-bottom: 0;
      }

      &:nth-child(odd) {
        margin-right: calculateRem(11px);
      }

      &:nth-child(even) {
        margin-left: calculateRem(11px);
      }
    }

    @include respond-to('tablet') {
      @include grid-col(3, $grid-columns: 3);

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.card__feature_full {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    margin-bottom: calc(#{$gap_tablet} - 1.5rem);
    @include grid-col(9, $grid-columns: 9);
  }

  @include respond-to('desktop') {
    margin-bottom: calc(#{$gap_desktop} - 1.5rem);
  }
}

.card__list_full {
  width: 100%;

  @include respond-to('tablet') {
    display: flex;
  }

  > div {
    padding-top: calculateRem(24px);
    padding-bottom: calculateRem(24px);
    border-bottom: 1px solid $color__rule-lines;

    @media (min-width: 280px) and (max-width: 614px) {
      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    @include respond-to('tablet') {
      width: 22%;
      margin-bottom: 0;
      // padding-top: calculateRem(6px);
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    @include respond-to('desktop') {
      // padding-top: calculateRem(16px);
    }
  }
}

.article__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 44px auto;

  @include respond-to('tablet') {
    margin: 66px auto;
  }

  @include respond-to('desktop') {
    margin: 90px auto;
  }

  &--background {
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    @include respond-to('tablet') {
      margin: 66px -1.875rem;
      width: calc(100% + 3.75rem);
    }

    @include respond-to('desktop') {
      margin: 90px -2.5rem;
      width: calc(100% + 5rem);
    }

    .article__cards {
      width: calc(100% - 2.5rem);
      margin: 44px auto;

      @include respond-to('tablet') {
        margin: 66px auto;
        width: calc(100% - 3.75rem);
      }

      @include respond-to('desktop') {
        margin: 90px auto;
        width: calc(100% - 5rem);
      }
    }
  }

  &--wheat {
    background-color: $color__light-wheat;
  }

  &--gray {
    background-color: $color__light-yellow;
  }

  &--green {
    background-color: $color__green;

    .article__cards_title {

      h2 {
        color: $color__wheat;
      }

      a {
        color: $color__wheat;

        &:hover {
          color: $color__rule-lines;
        }
      }

      &::after {
        background: $color__wheat;
      }
    }
  }
}

.message {
  color: $color__gray-dark;

  &--wheat {
    color: $color__wheat;
  }
}

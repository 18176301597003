@import '../../styles/partials/variables';

.general_content {
  line-height: 30px;
  @include convertToRem(18px);
  color: $color__gray-black;

  i,
  em {
    font-style: italic;
  }

  img{
    width: 100%
  }

  b,
  strong {
    font-weight: $black-weight;
  }

  a {
    color: $color__gray-black;

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    color: $color__green;
    font-weight: bold;
    font-size: 1.4em;
    margin-bottom: 32px;
  }
}

@import '../../styles/partials/variables';

.article__cards {
  margin: 44px auto;
  grid-row-gap: calculateRem(24px);

  @include respond-to('tablet') {
    margin: 66px auto;
    flex-wrap: wrap;
  }

  @include respond-to('desktop') {
    margin: 90px auto;
  }
}

.article__cards_title {
  display: flex;
  align-items: center;
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);
  }

  @include respond-to('desktop') {
    @include grid-col(9, $grid-columns: 9);
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: $bold-weight;
    color: $color__green;
    line-height: 30px;
    margin-right: calculateRem(12px);
    flex-shrink: 0;
    max-width: 80%;
    @include convertToRem(20px);
  }

  a {
    order: 3;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: $bold-weight;
    line-height: 1.25;
    color: $color__green;
    text-transform: uppercase;
    margin-left: calculateRem(11px);
    text-decoration: none;
    flex-shrink: 0;
    @include convertToRem(12px);
    @include letter-spacing(69);
  }

  &::after {
    content: '';
    width: 100%;
    flex: 1;
    height: 3px;
    display: block;
    background: $color__green;
  }
}

@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.container {
  padding: 75% 0 0 0;
  position: relative;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.caption {
  text-align: center;
  line-height: 30px;
  color: $color__gray-black;
  margin-top: calculateRem(30px);
  margin-bottom: calculateRem(30px);
  @include convertToRem(18px);
}

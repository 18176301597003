@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.ol,
.ul {
  list-style: disc;
  padding-left: calculateRem(20px);
  padding-right: calculateRem(20px);

  > li {
    @include convertToRem(18px);
    line-height: 30px;
    color: $color__gray-black;
    margin-top: calculateRem(5px);
    margin-bottom: calculateRem(5px);
  }
}

.ol {
  list-style: decimal;
}

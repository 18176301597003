@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.blockQuote {
  width: 100%;
  margin-top: calculateRem(30px);
  margin-bottom: calculateRem(30px);

  blockquote {
    p {
      @include convertToRem(18px);
      line-height: 30px;
      color: $color__gray-black;
    }
  }
}

@import '../../../styles/partials/mixins';
@import '../../../styles/partials/variables';

.cta {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: calculateRem(48px);
  text-align: center;

  p {
    @include convertToRem(14px);
    line-height: 21px;
    color: #e3e3e3;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: calculateRem(12px);

    @include respond-to('tablet') {
      flex-direction: row;
    }
    label {
      display: none;
    }
    input {
      @include convertToRem(15px);
      height: calculateRem(40px);
      margin-bottom: calculateRem(19px);
      padding-left: calculateRem(14px);
      padding-right: calculateRem(14px);
      font-family: $font__labels;
      color: $color__gray-black;

      &:focus {
        outline: 0;
      }
    }

    .hs-submit .hs-button {
      width: 100%;
      border-radius: calculateRem(25px);
      border: 0;
      height: calculateRem(40px);
      background-color: $color__wheat;
      color: $color__gray-black-dark;
      @include convertToRem(17px);
      line-height: 20px;
      font-family: $font__secondary;

      &:hover {
        background-color: $color__light-wheat;
      }
    }
  }

  @include respond-to('mobile2') {
    margin-top: calculateRem(37px);

    p {
      text-align: left;
    }

    form {
      margin-top: calculateRem(19px);
      flex-direction: row;

      input {
        margin-bottom: calculateRem(15px);
        height: calculateRem(34px);
      }
    }
  }

  @include respond-to('tablet') {
    margin-top: calculateRem(25px);
  }
}

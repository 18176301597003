@import '../../styles/partials/variables';

.article__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 44px auto;
  padding: 44px 0;

  @include respond-to('tablet') {
    padding: 66px 0;
    margin: 66px auto;
  }

  @include respond-to('desktop') {
    padding: 0px 0;
    margin: 0px auto;
  }

  &--background {
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    margin-right: -1.25rem;

    @include respond-to('tablet') {
      margin: 66px -1.875rem;
      padding: 66px 0;
      width: calc(100% + 3.75rem);
    }

    @include respond-to('desktop') {
      margin: 90px -2.5rem;
      padding: 90px 0;
      width: calc(100% + 5rem);
    }
  }

  &--wheat {
    background-color: $color__light-wheat;
  }

  &--gray {
    background-color: $color__light-yellow;
  }

  &--green {
    background-color: $color__green;

    .article__cards_title {
      h2 {
        color: $color__wheat;
      }

      a {
        color: $color__wheat;

        &:hover {
          color: $color__rule-lines;
        }
      }

      &::after {
        background: $color__wheat;
      }
    }
  }
}

.featured__card {
  @include grid-col(2, $grid-columns: 2);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calculateRem(16px);

  @include respond-to('mobile2') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(3.17, $grid-columns: 9);
    padding: calculateRem(24px) calculateRem(60px);
  }
}

.featured__header {
  display: flex;
}

.featured__date {
  display: flex;
  flex-direction: column;
  font-family: $font__poppins;
  font-weight: $bold-weight;
  @include convertToRem(15px);
}

.featured__image {
  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile2') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(5.83, $grid-columns: 9);
  }
}

.image__wrapper {
  position: relative;
  padding-bottom: 54.16%;
  overflow: hidden;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.featured__label {
  font-family: $font__secondary;
  font-weight: $bold-weight;
  color: $color__gray-black-dark;
  margin-bottom: calculateRem(16px);
  position: relative;
  display: inline-block;
  align-self: flex-start;
  @include convertToRem(16px);
  span {
    position: relative;
    z-index: 2;
    padding-left: 10px;
    padding-right: 30px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -4px;
    left: 0;
    width: calc(100% - 20px);
    height: 11px;
    background: $color__wheat;
  }
}

.featured__title {
  @include convertToRem(25px);
  line-height: calculateRem(31px);
  color: $color__green;
  font-weight: $bold-weight;
  margin-bottom: calculateRem(20px);
  font-family: $font__poppins;
  text-decoration: none;
  display: block;

  @include respond-to('mobile2') {
    font-weight: $xbold-weight;
    @include convertToRem(25px);
    line-height: 31px;
  }
}

.featured__excerpt {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: $color__gray-dark;
  margin-bottom: 9px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
  @include convertToRem(14px);
}

.featured__duration {
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.25;
  color: $color__gray-dark;
  @include convertToRem(12px);
  @include letter-spacing(69);
}

.featured__location{
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.25;
  color: $color__gray-dark;
  margin: calculateRem(8px) 0;
  @include convertToRem(12px);
  @include letter-spacing(69);
}
